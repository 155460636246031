import React,{useState} from 'react'

const Form = () => {
    const [formData, setFormData]=useState({
        name:"",
        email:"",
        phone:"",
        designation:"",
        company:"",
        city:"",
        state:"",
        country:"",
        category:'',
        message:""
      })
       const [errors, setErrors] = useState({});

      const validate = () => {
         const newErrors = {};
          if (!formData.name) newErrors.name = "Name is required.";
          if (!formData.email) {
          newErrors.email = "Email is required.";
          } else if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email)) {
            newErrors.email = "Invalid email address.";
          }
          if (!formData.phone) {
          newErrors.phone = "Phone number is required.";
        } else if (!/^[0-9]{10,15}$/.test(formData.phone)) {
          newErrors.phone = "Phone number must be 10-15 digits.";
        }
      if (!formData.designation) newErrors.designation = "Designation is required.";
      if (!formData.company) newErrors.company = "Company name is required.";
      if (!formData.city) newErrors.city = "City is required.";
      if (!formData.state) newErrors.state = "State is required.";
      if (!formData.country) newErrors.country = "Country is required.";
      if (!formData.category) newErrors.category = "Category is required.";
      if (!formData.message) newErrors.message = "Message is required.";
        setErrors(newErrors);
      return Object.keys(newErrors).length === 0;


      }
      
      const handleChange=(e)=>{
        const { value,name}=e.target;
        setFormData((prevData)=>({
          ...prevData,
          [name]:value
        }))
      }

      const handleSubmit = (e)=>{
        e.preventDefault();
         if (validate()) {
      console.log("Form data submitted:", formData);
      alert("Form submitted successfully!");
      setFormData({
        name: "",
        email: "",
        phone: "",
        designation: "",
        company: "",
        city: "",
        state: "",
        country: "",
        category: "",
        message: "",
      });
      setErrors({});
    }
         
      }
  return (
            <form onSubmit={handleSubmit}>

           <div className="mb-4">
              <input
                type='text'
                className='form-control'
                placeholder='Full Name'
                id='name'
                name='name'
                value={formData.name}
                onChange={handleChange}
               
              />
              {errors.name && <p className='text-danger'>{errors.name}</p>}
            </div>

            <div className="mb-4">
              <input 
               type='email'
               placeholder='Email'
               className='form-control'
               id='email'
               name='email'
               value={formData.email}
               onChange={handleChange}
             
              />
               {errors.email && <p className='text-danger'>{errors.email}</p>}
            </div>

            <div className="row">

              <div className="col-lg-6 col-md-6">
              <div className="mb-4">
              <input 
               type='number'
               placeholder='Phone Number'
               className='form-control'
               id='phone'
               name='phone'
               value={formData.phone}
               onChange={handleChange}
               
              />
              {errors.phone && <p className='text-danger'>{errors.phone}</p>}
             </div>
              </div>

              <div className="col-lg-6 col-md-6">
              <div className="mb-4">
              <input
                type='text'
                className='form-control'
                placeholder='Designation'
                id='designation'
                name='designation'
                value={formData.designation}
                onChange={handleChange}
                
              />
               {errors.designation && <p className='text-danger'>{errors.designation}</p>}
            </div>
              </div>
            </div>

          <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="mb-4">
              <input
                type='text'
                className='form-control'
                placeholder='Company Name'
                id='company'
                name='company'
                value={formData.company}
                onChange={handleChange}
                
              />
              {errors.company && <p className='text-danger'>{errors.company}</p>}
            </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
            <div className="mb-4">
              <input
                type='text'
                className='form-control'
                placeholder='City'
                id='city'
                name='city'
                value={formData.city}
                onChange={handleChange}
               
              />
              {errors.city && <p className='text-danger'>{errors.city}</p>}
            </div>
            </div>

           

          </div>
            
          <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="mb-4">
              <input
                type='text'
                className='form-control'
                placeholder='State'
                id='state'
                name='state'
                value={formData.state}
                onChange={handleChange}
                
              />
              {errors.state && <p className='text-danger'>{errors.state}</p>}
            </div>
            </div>
            <div className="col-lg-6 col-md-6">
            <div className="mb-4">
              <input
                type='text'
                className='form-control'
                placeholder='Country'
                id='country'
                name='country'
                value={formData.country}
                onChange={handleChange}
                
              />
              {errors.country && <p className='text-danger'>{errors.country}</p>}
            </div>
            </div>

         
          </div>
           

            <div className="mb-4">
              <select
              className="form-select"
              id='category'
              name="category"
              value={formData.category}
              onChange={handleChange}
             
              >
               <option value="">Choose your category</option>
              <option value="Client">Client</option>
              <option value="Investor">Investor</option>
              <option value="Partner">Partner</option>
              <option value="Agent">Agent</option> 
              <option value="Educational Institute">Educational Institute</option> 

              </select>
              {errors.category && <p className='text-danger'>{errors.category}</p>}
            </div>


            <div className="mb-4">
            <textarea
            className="form-control"
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="4"
            placeholder="Enter your message"
             >
            </textarea>
            {errors.message && <p className='text-danger'>{errors.message}</p>}
            </div>

            <button type="submit" className="btn btn-danger home-btn">Submit</button>
            
           </form>
  )
}

export default Form
