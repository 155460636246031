import React from 'react'
import './Contacts.css'
import Form from '../../components/Contact Form/Form';
import Map from '../../components/Map/Map'
import image from '../../assets/images/Contact/image3.png'

const Contacts = () => {
    
  return (
    <>
      <div className="container py-lg-5 py-md-4 py-3">
        <div className="row">
          <h1 className='text-dark-beetroot fw-bold text-center'>Contact Us</h1><hr className='main-hr' />
        </div>
        <div className="row">
          <h5>Locate our offices near by you.</h5>
          <div className="mt-3  office-search">
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
              />

          <i className="bi bi-search"></i>
        
          </div>
        </div>
        <div className="row mt-5">
          <Map/>
        </div>
      </div>

      <div className="container py-lg-5 py-md-4 py-3 ">
        <div className="row form-container">
          <div className="col-lg-6 col-md-6 contact-image">
              <img className='img-fluid' src={image} alt="Contact Us" />
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="p-lg-4 p-3 ">
            <h2 className='mb-3 fw-bold text-center text-dark-beetroot'>Get in Touch</h2>
            <Form/>
            </div>
           
          </div>
      </div>
      </div>

      <div className="container py-lg-5 py-md-4 py-3 ">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6">
            <h5>Branch Office</h5>
            <p>
            Suit G/11, B-8 sector -2, Near by sector-15 metro station Noida, Uttar Pradesh - 201301 India</p>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <h5>Corporate Office</h5>
            <p>Gate No. 2, Lane II, Ashutosh City Bareilly
            Uttar Pradesh - 243006 India</p>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-6">
            <h5>Contact Us</h5>
            <div><i class="bi bi-envelope"></i> contact@techtorch.solutions</div>
            <div><i class="bi bi-telephone"></i> 0581 4053576</div>
            <div><i class="bi bi-phone"></i> 7251090147</div>
          </div>
          
        </div>
      </div>
    </>
  )
}

export default Contacts